// src/context/scoreContext.js
import { createContext, useState } from 'react';
import { scoreConfig } from '../config/config';

const ScoreContext = createContext();

const ScoreProvider = ({ children }) => {
    const [score, setScore] = useState(0);

    const addToScore = (addedValue) => {
        setScore((prevScore) => prevScore + addedValue);
    };

    const updateScoreAfterQuestion = (isCorrect, usedHints) => {
        var result = 0;
        var questionValue = 0;
        var hintsValue = usedHints * scoreConfig.hint_used;

        if (isCorrect) {
            questionValue = scoreConfig.correct_answer;
        }
        else {
            questionValue = scoreConfig.wrong_answer;
        }
        
        result = questionValue + hintsValue;

        if (result < 0) {
            result = 0;
        }
        
        setScore((prevScore) => prevScore + result);

    };

    const resetScore = () => {
        setScore(0);
    };

    return (
        <ScoreContext.Provider value={{ score, addToScore, updateScoreAfterQuestion, resetScore }}>
            {children}
        </ScoreContext.Provider>
    );
};

export { ScoreContext, ScoreProvider };
