// apiConfig.js
const getBaseUrl = () => {
    var url = '';
    if (process.env.NODE_ENV === 'development') {
        // Code to run in development environment
        console.log('Running in development mode');
        url = 'http://localhost:8080/api';
      } else {
        // Code to run in production environment
        console.log('Running in production mode');
        url =  './api';
      }
      
    return url;
}

const API_BASE_URL = getBaseUrl();

// Localization endpoints
export const LOCALIZATIONS_ENDPOINT = `${API_BASE_URL}/localizations`;
export const LOCALIZATION_BY_CODE_ENDPOINT = (code) => `${LOCALIZATIONS_ENDPOINT}/${code}`;

// States endpoints
export const STATES_ENDPOINT = `${API_BASE_URL}/states`;
export const STATE_BY_ID_ENDPOINT = (stateId) => `${STATES_ENDPOINT}/${stateId}`;
export const STATE_BY_NAME_ENDPOINT = (stateName) => `${STATES_ENDPOINT}/by-name/${stateName}`;

// UI Elements endpoints
export const UI_ELEMENTS_ENDPOINT = `${API_BASE_URL}/ui_elements`;
export const UI_ELEMENTS_BY_LOCALIZATION_CODE_ENDPOINT = (code) => `${UI_ELEMENTS_ENDPOINT}/localized/${code}`;
export const UI_ELEMENT_BY_ID_ENDPOINT = (uiElementId) => `${UI_ELEMENTS_ENDPOINT}/${uiElementId}`;
export const UI_ELEMENT_BY_ID_AND_LOCALIZATION_ENDPOINT = (uiElementId, localizationCode) => `${UI_ELEMENT_BY_ID_ENDPOINT(uiElementId)}/localized/${localizationCode}`;
export const UI_ELEMENTS_BY_STATE_ID_AND_LOCALIZATION_ENDPOINT = (stateId, localizationCode) => `${UI_ELEMENTS_ENDPOINT}/stateId/${stateId}/localized/${localizationCode}`;

// Users endpoints
export const USERS_ENDPOINT = `${API_BASE_URL}/users`;
export const USER_BY_ID_ENDPOINT = (userId) => `${USERS_ENDPOINT}/${userId}`;
export const USER_BY_LAST_NAME_ENDPOINT = (lastName) => `${USERS_ENDPOINT}/by-last-name/${lastName}`;
export const USER_BY_FIRST_NAME_ENDPOINT = (firstName) => `${USERS_ENDPOINT}/by-first-name/${firstName}`;
export const UPDATE_PREFERRED_LANGUAGE_ENDPOINT = (userId) => `${USERS_ENDPOINT}/update/${userId}/set-preferred-language`;

// Quiz Runs endpoints
export const QUIZ_RUNS_ENDPOINT = `${API_BASE_URL}/quiz_runs`;
export const QUIZ_RUNS_BY_USER_ENDPOINT = (userId) => `${QUIZ_RUNS_ENDPOINT}/user/${userId}`;
export const QUIZ_RUN_BY_ID_ENDPOINT = (quizId) => `${QUIZ_RUNS_ENDPOINT}/${quizId}`;
export const BEST_RUN_FOR_USER_ENDPOINT = (userId) => `${QUIZ_RUNS_ENDPOINT}/best/${userId}`;
export const CURRENT_RUN_FOR_USER_ENDPOINT = (userId) => `${QUIZ_RUNS_ENDPOINT}/current/${userId}`;
export const INSERT_QUIZ_RUN_ENDPOINT = `${QUIZ_RUNS_ENDPOINT}/insert`;
export const UPDATE_QUIZ_RUN_ENDPOINT = (quizId) => `${QUIZ_RUNS_ENDPOINT}/update/${quizId}`;

// Answered Questions endpoints
export const ANSWERED_QUESTIONS_ENDPOINT = `${API_BASE_URL}/answered_questions`;
export const INSERT_ANSWERED_QUESTION_ENDPOINT = `${ANSWERED_QUESTIONS_ENDPOINT}/insert`;
export const UPDATE_ANSWERED_QUESTION_ENDPOINT = (id) => `${ANSWERED_QUESTIONS_ENDPOINT}/update/${id}`;
export const ANSWERED_QUESTIONS_BY_RUN_ENDPOINT = (run_id) => `${ANSWERED_QUESTIONS_ENDPOINT}/by-run/${run_id}`;
export const LAST_ANSWERED_QUESTION_BY_RUN_ENDPOINT = (run_id) => `${ANSWERED_QUESTIONS_ENDPOINT}/last-answered/${run_id}`;

// Selected Answers endpoints
export const SELECTED_ANSWERS_ENDPOINT = `${API_BASE_URL}/selected_answers`;
export const INSERT_SELECTED_ANSWER_ENDPOINT = `${SELECTED_ANSWERS_ENDPOINT}/insert`;
export const SELECTED_ANSWERS_BY_ANSWERED_QUESTION_ENDPOINT = (answered_question_id) => `${SELECTED_ANSWERS_ENDPOINT}/by-answered-question/${answered_question_id}`;
export const UPDATE_SELECTED_ANSWER_ENDPOINT = (answered_question_id, answer_id) => `${SELECTED_ANSWERS_ENDPOINT}/update/${answered_question_id}/${answer_id}`;

// Questions endpoints
export const QUESTIONS_ENDPOINT = `${API_BASE_URL}/questions`;
export const QUESTIONS_BY_LOCALIZATION_CODE_ENDPOINT = (code) => `${QUESTIONS_ENDPOINT}/localized/${code}`;
export const QUESTION_BY_ID_ENDPOINT = (id) => `${QUESTIONS_ENDPOINT}/${id}`;
export const QUESTION_BY_ID_AND_CODE_ENDPOINT = (id, code) => `${QUESTIONS_ENDPOINT}/${id}/localized/${code}`;

// Answers endpoints
export const ANSWERS_ENDPOINT = `${API_BASE_URL}/answers`;
export const ANSWER_BY_ID_ENDPOINT = (answer_id) => `${ANSWERS_ENDPOINT}/${answer_id}`;
export const ANSWER_BY_ID_AND_LOCALIZATION_CODE_ENDPOINT = (answer_id, localization_code) =>
    `${ANSWERS_ENDPOINT}/${answer_id}/localized/${localization_code}`;
export const ANSWERS_BY_QUESTION_ENDPOINT = (question_id) => `${ANSWERS_ENDPOINT}/by-question/${question_id}`;
export const ANSWERS_BY_QUESTION_AND_LOCALIZATION_CODE_ENDPOINT = (question_id, localization_code) =>
    `${ANSWERS_ENDPOINT}/by-question/${question_id}/localized/${localization_code}`;

// Hints endpoints
export const HINTS_ENDPOINT = `${API_BASE_URL}/hints`;
export const HINT_BY_ID_ENDPOINT = (hint_id) => `${HINTS_ENDPOINT}/${hint_id}`;
export const HINTS_BY_QUESTION_ENDPOINT = (question_id) => `${HINTS_ENDPOINT}/by-question/${question_id}`;
export const HINTS_BY_QUESTION_AND_LOCALIZATION_CODE_ENDPOINT = (question_id, localization_code) =>
  `${HINTS_ENDPOINT}/by-question/${question_id}/localized/${localization_code}`;