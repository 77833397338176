// src/components/Quiz.js
import React, { useContext, useEffect, useState } from 'react';
import { mediaConfig, gameplayConfig } from '../config/config.js';
import { StateContext } from '../context/stateContext';
import { LocalizationContext } from '../context/localizationContext';
import { ScoreContext } from '../context/scoreContext.js';
import { fetchQuizQuestions } from '../services/quiz.service';
import { fetchUIElementsByStateAndLocalizationCode } from '../services/ui_elements.service';
import '../css/Quiz.css';

function Quiz() {
  const { quiz_video_url, safran_logo_url } = mediaConfig;
  const { currentState, setCurrentState, stateNameToInteger } = useContext(StateContext);
  const { score, updateScoreAfterQuestion } = useContext(ScoreContext);
  const { activeLanguage } = useContext(LocalizationContext);
  const [quizQuestions, setQuizQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [isCorrect, setIsCorrect] = useState(null);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [currentHintIndex, setCurrentHintIndex] = useState(0);
  const [showHint, setShowHint] = useState(false);
  const [hintText, setHintText] = useState('');
  const [confirmButtonText, setConfirmButtonText] = useState('');
  const [nextButtonText, setNextButtonText] = useState('');
  const [usedHintsCount, setUsedHintsCount] = useState(0);
  const [correctText, setCorrectText] = useState('');
  const [incorrectText, setIncorrectText] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch quiz questions
        const questions = await fetchQuizQuestions(activeLanguage);
        setQuizQuestions(questions);
  
        // Fetch UI elements
        const uiElements = await fetchUIElementsByStateAndLocalizationCode(stateNameToInteger[currentState], activeLanguage);
  
        // Process UI elements
        uiElements.forEach((element) => {
          switch (element.element_name) {
            case 'hint_button':
              setHintText(element.element_text || '');
              break;
            case 'confirm_button':
              setConfirmButtonText(element.element_text || '');
              break;
            case 'next_button':
              setNextButtonText(element.element_text || '');
              break;
            case 'feedback_correct':
              setCorrectText(element.element_text || '');
              break;
            case 'feedback_incorrect':
              setIncorrectText(element.element_text || '');
              break;
            default:
              break;
          }
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    // Call the fetchData function
    fetchData();
  
    // The effect should run when activeLanguage or currentState changes
  }, [activeLanguage, currentState, stateNameToInteger]);

  const handleAnswerSelect = (answerId) => {
    if (!isConfirmed) {
      // If canSelectMoreThanOneAnswer is true, toggle the selected state of the answer
      if (gameplayConfig.canSelectMoreThanOneAnswer) {
        setSelectedAnswers((prevSelected) =>
          prevSelected.includes(answerId)
            ? prevSelected.filter((id) => id !== answerId)
            : [...prevSelected, answerId]
        );
      } else {
        // If canSelectMoreThanOneAnswer is false, only select the chosen answer
        setSelectedAnswers([answerId]);
      }
    }
  };

  const handleConfirm = () => {
    // Check correctness of selected answers
    const correctAnswers = quizQuestions[currentQuestionIndex].answers.filter(
      (answer) => answer.is_correct
    );
    const isUserCorrect = selectedAnswers.sort().join(',') === correctAnswers.map((a) => a.answer_id).sort().join(',');

    // Provide feedback and update state
    setIsCorrect(isUserCorrect);
    setIsConfirmed(true);
    updateScoreAfterQuestion(isUserCorrect, usedHintsCount);
  };

  const getHint = () => {
    if (!isConfirmed) {
      // Display the next hint when the user clicks "Hint+"
      if (!showHint) {
        setShowHint(true);
        setCurrentHintIndex(0);
      }
      else {
        setCurrentHintIndex((prevIndex) => (prevIndex + 1) % quizQuestions[currentQuestionIndex].hints.length);
      }

      if (usedHintsCount < quizQuestions[currentQuestionIndex].hints.length) {
        setUsedHintsCount(usedHintsCount + 1);
      }
    }
  };

  const resetValues = () => {
    setSelectedAnswers([]);
    setIsCorrect(null);
    setIsConfirmed(false);
    setCurrentHintIndex(0);
    setShowHint(false); // Hide the hint when moving to the next question
    setUsedHintsCount(0);
  }

  const handleNextQuestion = () => {
    // Reset values
    resetValues();

    // Check if there is a next question
    const hasNextQuestion = currentQuestionIndex < quizQuestions.length - 1;
    // Move to the next question if available
    if (hasNextQuestion) {
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
    } else {
      // If the current question is the last one, set the current state to "scoreboard"
      setCurrentState('scoreboard');
    }
  };

  return (
    <div className="App" style={{ backgroundImage: `url(${quiz_video_url})` }}>
      <video className="video-background" autoPlay loop muted>
        <source src={quiz_video_url} type="video/mp4" />
      </video>
      <div className="quiz-content">
        {quizQuestions.length > 0 && (
          <div>
            <h2 className='question-index'>Question {quizQuestions[currentQuestionIndex].question_order}</h2>
            <p className='question-title'>{quizQuestions[currentQuestionIndex].question_text}</p>
            {/* Display answers */}
            <div className='question-hint-container'>
              {quizQuestions[currentQuestionIndex].answers ? (
                <ul className='question-list'>
                  {quizQuestions[currentQuestionIndex].answers.map((answer) => (
                    <li
                      key={answer.answer_id}
                      className={`answer-button ${isConfirmed ? (answer.is_correct ? 'correct' : 'incorrect') : (selectedAnswers.includes(answer.answer_id) ? 'selected' : '')}`}
                      onClick={() => handleAnswerSelect(answer.answer_id)}
                    >
                      {answer.answer_order}. {answer.answer_text}
                    </li>
                  ))}
                </ul>
              ) : (
                <p>No answers found for this question</p>
              )}

              {showHint && !isConfirmed && currentHintIndex < quizQuestions[currentQuestionIndex].hints.length && (
                <div className='hint-container'>
                  <p className='hint hint-title'>{hintText} {quizQuestions[currentQuestionIndex].hints[currentHintIndex].hint_order}</p>
                  <p className='hint'>{quizQuestions[currentQuestionIndex].hints[currentHintIndex].hint_text}</p>
                </div>
              )}
            </div>
            {/* Display feedback */}
            <div className={isCorrect !== null ? (isCorrect ? 'correct-text' : 'incorrect-text') : 'empty'}>
              {isCorrect ? correctText : incorrectText}
            </div>
            {/* Display buttons */}
            {!isConfirmed && (
              <button className='confirm-button' onClick={handleConfirm} disabled={selectedAnswers.length === 0}>
                {confirmButtonText}
              </button>
            )}
            {isConfirmed && (
              <button className='confirm-button' onClick={handleNextQuestion} disabled={!isConfirmed}>
                {nextButtonText}
              </button>
            )}
            {!isConfirmed && (
              <button className='confirm-button hint-button' onClick={getHint} disabled={isConfirmed}>
                {hintText} +
              </button>
            )}

          </div>
        )}
      </div>

      <img src={safran_logo_url} className="safranLogo" />

    </div>
  );
}

export default Quiz;
