// src/config/config.js

export const gameplayConfig = {
  canSelectMoreThanOneAnswer: false,
  showVideoBeforeQuestion: true
}

export const mediaConfig = {
  home_video_url: '/assets/videos/home.mp4',
  quiz_video_url: '/assets/videos/quiz.mp4',
  intro_transition_url: '/assets/videos/intro.mp4',
  safran_logo_url: '/assets/images/T_Safran_White.png'
};

export const scoreConfig = {
  correct_answer: 25,
  wrong_answer: 0,
  hint_used: -5
};