// src/services/quiz.service.js
import {
  QUESTIONS_BY_LOCALIZATION_CODE_ENDPOINT,
  ANSWERS_BY_QUESTION_AND_LOCALIZATION_CODE_ENDPOINT,
  HINTS_BY_QUESTION_AND_LOCALIZATION_CODE_ENDPOINT,
} from '../config/apiConfig';

export const fetchQuizQuestions = async (localizationCode) => {
  try {
    const endpoint = QUESTIONS_BY_LOCALIZATION_CODE_ENDPOINT(localizationCode);
    const response = await fetch(endpoint);
    if (response.ok) {
      const questions = await response.json();
      console.log('fetched questions:');
      console.log(questions);

      // Fetch answers and hints for each question
      const questionsWithAnswersAndHints = await Promise.all(
        questions.map(async (question) => {
          const answersEndpoint = ANSWERS_BY_QUESTION_AND_LOCALIZATION_CODE_ENDPOINT(
            question.question_id,
            localizationCode
          );
          const hintsEndpoint = HINTS_BY_QUESTION_AND_LOCALIZATION_CODE_ENDPOINT(
            question.question_id,
            localizationCode
          );

          const [answersResponse, hintsResponse] = await Promise.all([
            fetch(answersEndpoint),
            fetch(hintsEndpoint),
          ]);

          if (answersResponse.ok && hintsResponse.ok) {
            const answers = await answersResponse.json();
            const hints = await hintsResponse.json();
            return { ...question, answers, hints };
          } else {
            throw new Error('Failed to fetch answers or hints for question');
          }
        })
      );
      return questionsWithAnswersAndHints;
    } else {
      throw new Error('Failed to fetch quiz questions');
    }
  } catch (error) {
    console.error('Error fetching quiz questions:', error);
    throw error;
  }
};
