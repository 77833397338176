import React, { useContext, useEffect, useState } from 'react';
import { mediaConfig } from '../config/config.js';
import { StateContext } from '../context/stateContext';
import { /*textReveal, */fadeIn } from '../animations';
import { LocalizationContext } from '../context/localizationContext'; // Import your localization context
import { fetchUIElementsByStateAndLocalizationCode } from '../services/ui_elements.service'; // Import your UI element service

function Intro() {
  const { quiz_video_url, safran_logo_url } = mediaConfig;
  const { setCurrentState, stateNameToInteger } = useContext(StateContext);
  const { activeLanguage } = useContext(LocalizationContext);
  const [introTitle, setIntroTitle] = useState('');
  const [introSubtitle, setIntroSubtitle] = useState('');
  const [startQuizButtonText, setStartQuizButtonText] = useState('');

  useEffect(() => {
    // Get a reference the necessary elements
    const titleElement = document.querySelector('.title');
    const subtitleElement = document.querySelector('.subtitle');

    // Fetch UI elements for Intro state
    fetchUIElementsByStateAndLocalizationCode(stateNameToInteger['intro'], activeLanguage)
      .then((uiElements) => {
        const introTitleElement = uiElements.find((element) => element.element_name === 'intro_title');
        const introSubtitleElement = uiElements.find((element) => element.element_name === 'intro_subtitle');
        const startQuizElement = uiElements.find((element) => element.element_name === 'start_quiz_button')

        if (introTitleElement) {
          const titleText = introTitleElement.element_text;
          setIntroTitle(titleText);
          titleElement.style.opacity = 1;
          //textReveal(titleElement);
        }

        if (introSubtitleElement) {
          const subtitleText = introSubtitleElement.element_text;
          setIntroSubtitle(subtitleText);
          subtitleElement.style.opacity = 1;
          //textReveal(subtitleElement);
        }

        if(startQuizElement) {
          setStartQuizButtonText(startQuizElement.element_text);
        }
      })
      .catch((error) => {
        console.error('Error fetching UI elements:', error);
      });

    // Add a delay before fading in the "Start Quiz" button
    setTimeout(() => {
      fadeIn('.start-app-button', 0.2);
    }, 2000); // Adjust the delay time as needed
  }, [activeLanguage, stateNameToInteger]);

  const handleStartQuizClick = () => {
    // Set the current state to 'Quiz' when the button is clicked
    setCurrentState('quiz');
  };

  return (
    <div className="App" style={{ backgroundImage: `url(${quiz_video_url})` }}>
      <video className="video-background" autoPlay loop muted>
        <source src={quiz_video_url} type="video/mp4" />
      </video>
      <div className='header'>
        <h1 className="title">{introTitle}</h1>
        <h2 className="subtitle">{introSubtitle}</h2>
      </div>
      <div className='center'>
        <button className="start-app-button intro-variation" onClick={handleStartQuizClick}>
          {startQuizButtonText}
        </button>
      </div>
      <img src={safran_logo_url} className="safranLogo"/>
    </div>
  );
}

export default Intro;
