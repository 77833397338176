// src/context/localizationContext.js
import React, { createContext, useState } from 'react';

const LocalizationContext = createContext();

const LocalizationProvider = ({ children }) => {
  const [activeLanguage, setActiveLanguage] = useState('en'); // Your default active language
  const [localizations, setLocalizations] = useState([]); // Initialize with an empty array

  return (
    <LocalizationContext.Provider value={{ activeLanguage, setActiveLanguage, localizations, setLocalizations }}>
      {children}
    </LocalizationContext.Provider>
  );
};

export { LocalizationContext, LocalizationProvider };
