// src/context/StateContext.js

import React, { createContext, useState } from 'react';

const StateContext = createContext();

const StateProvider = ({ children }) => {
  const [currentState, setCurrentState] = useState('home');

  // Define a mapping of state names to integers
  const stateNameToInteger = {
    home: 1,
    intro: 2,
    quiz: 3,
    scoreboard: 4
    // Add more states as needed
  };

  return (
    <StateContext.Provider value={{ currentState, setCurrentState, stateNameToInteger }}>
      {children}
    </StateContext.Provider>
  );
};

export { StateContext, StateProvider };