// src/services/uiElementService.js
import { UI_ELEMENTS_BY_LOCALIZATION_CODE_ENDPOINT, UI_ELEMENTS_BY_STATE_ID_AND_LOCALIZATION_ENDPOINT } from '../config/apiConfig';

export const fetchUIElementsByLocalizationCode = async (localizationCode) => {
  try {
    const endpoint = UI_ELEMENTS_BY_LOCALIZATION_CODE_ENDPOINT(localizationCode);
    const response = await fetch(endpoint);
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      throw new Error(`Failed to fetch UI elements for localization code: ${localizationCode}`);
    }
  } catch (error) {
    console.error('Error fetching UI elements:', error);
    throw error;
  }
};

export const fetchUIElementsByStateAndLocalizationCode = async (stateId, localizationCode) => {
    try{
        const endpoint = UI_ELEMENTS_BY_STATE_ID_AND_LOCALIZATION_ENDPOINT(stateId, localizationCode);
        const response = await fetch(endpoint);
        if(response.ok) {
            const data = await response.json();
            return data;
        } else {
            throw new Error(`Failed to fetch UI elements for localization code: ${localizationCode} and state id: ${stateId}`);
        }
    } catch (error) {
        console.error('Error fetching UI elements by state and loc:', error);
        throw error;
    }
};