import { gsap } from 'gsap';
import SplitType from 'split-type'

// Function to animate the video background
function animateVideoBackground() {
    return gsap.to('.video-background', { y: '100%', duration: 1, ease: 'power3.in' });
}

// Function to fade in an element
function fadeIn(element, duration = 0.5) {
    return gsap.to(element, { opacity: 1, duration, ease: 'power3.in' });
}

// Function to fade out an element
function fadeOut(element, duration = 0.5) {
    return gsap.to(element, { opacity: 0, duration, ease: 'power3.in' });
}

function animateTitle() {
    return gsap.fromTo('.title', { y: '5%' }, { y: '0%', duration: 3, ease: 'power3.out' });
}

function animateSubtitle() {
    return gsap.fromTo('.subtitle', { y: '5%' }, { y: '0%', duration: 3, ease: 'power3.out' });
}

function textReveal(elementSelector) {
    const ourText = new SplitType(elementSelector, { types: 'chars' });
    const chars = ourText.chars;

    gsap.fromTo(
        chars,
        {
            y: 100,
            opacity: 0,
        },
        {
            y: 0,
            opacity: 1,
            stagger: 0.05,
            duration: 1.5,
            ease: 'power4.out',
        }
    );
}

export { fadeIn, fadeOut, animateVideoBackground, animateTitle, animateSubtitle, textReveal };