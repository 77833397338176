import React, { useContext} from 'react';
import './App.css';
import Home from './components/Home';
import Intro from './components/Intro';
import Quiz from './components/Quiz';
import Scoreboard from './components/Scoreboard';
import { StateContext } from './context/stateContext';

function App() {
  const { currentState } = useContext(StateContext);

  return (
    <div className="App">
      {currentState === 'home' && <Home />}
      {currentState === 'intro' && <Intro />}
      {currentState === 'quiz' && <Quiz />}
      {currentState === 'scoreboard' && <Scoreboard />}
    </div>
  );
}

export default App;