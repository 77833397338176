import React, { useContext, useState, useEffect } from 'react';
import { mediaConfig } from '../config/config.js';
import { StateContext } from '../context/stateContext';
import { animateVideoBackground, fadeIn, fadeOut/* , textReveal */} from '../animations'; // Import your animation functions
import { LocalizationContext } from '../context/localizationContext'; // Import your localization context
import { fetchUIElementsByStateAndLocalizationCode } from '../services/ui_elements.service'; // Import your UI element service
import { fetchLocalizations } from '../services/localization.service';

function Home() {
  const { home_video_url, intro_transition_url, safran_logo_url } = mediaConfig;
  const { currentState, setCurrentState, stateNameToInteger } = useContext(StateContext);
  const { activeLanguage, setActiveLanguage, localizations, setLocalizations } = useContext(LocalizationContext);
  const [userInputsDisabled, setUserInputsDisabled] = useState(false);
  const [startAppButtonText, setStartAppButtonText] = useState('');
  const [skipButtonText, setSkipButtonText] = useState('');
  const [showSkipButton, setShowSkipButton] = useState(false);

  useEffect(() => {
    const startButton = document.querySelector('.start-app-button');
    fadeIn(startButton, 0.2);

    fetchUIElementsByStateAndLocalizationCode(stateNameToInteger[currentState], activeLanguage)
      .then((uiElements) => {
        const startAppButtonElement = uiElements.find((element) => element.element_name === 'start_app_button');
        const skipButtonElement = uiElements.find((element) => element.element_name === 'skip_button');

        if (startAppButtonElement) {
          const elementText = startAppButtonElement.element_text;

          if (elementText) {
            setStartAppButtonText(elementText);
          } else {
            console.log('Start App Button Element not found.');
          }
        }

        if (skipButtonElement) {
          const elementText = skipButtonElement.element_text;

          if (elementText) {
            setSkipButtonText(elementText);
          } else {
            console.log('Skip Button Element not found.');
          }
        }
      })
      .catch((error) => {
        console.error('Error fetching UI elements:', error);
      });

      fetchLocalizations()
      .then((data) => {
        setLocalizations(data);
      })
      .catch((error) => {
        console.error('Error fetching localizations:', error);
      });

  }, [activeLanguage, currentState, stateNameToInteger, setLocalizations]);

  const handleStartAppClick = () => {
    setUserInputsDisabled(true);
    fadeOut('.start-app-button', 0.2);

    animateVideoBackground()
      .then(() => {
        setShowSkipButton(true); // Show skip button when transition video starts playing

        return new Promise((resolve) => {
          const transitionVideo = document.createElement('video');
          transitionVideo.src = intro_transition_url;
          transitionVideo.autoplay = true;
          transitionVideo.muted = true;
          transitionVideo.onended = () => {
            setShowSkipButton(false); // Hide skip button when transition video ends
            resolve();
          };
          transitionVideo.className = 'transition-video';
          document.body.appendChild(transitionVideo);
        });
      })
      .then(() => {
        return fadeOut('.transition-video');
      })
      .then(() => {
        const transitionVideo = document.querySelector('.transition-video');
        if (transitionVideo) {
          document.body.removeChild(transitionVideo);
        }

        setCurrentState('intro');
      });
  };

  const handleSkipButtonClick = () => {
    // Stop ongoing animations and hide transition video
    stopAnimations();

    // Skip to the intro directly
    setShowSkipButton(false);
    setCurrentState('intro');
  };

  const stopAnimations = () => {
    // Add logic here to stop or reset any ongoing animations
    // You might want to cancel any timers or tweens

    // Hide the transition video
    const transitionVideo = document.querySelector('.transition-video');
    if (transitionVideo) {
      document.body.removeChild(transitionVideo);
    }
  };

  return (
    <div className="App" style={{ backgroundImage: `url(${home_video_url})` }}>
      <video className="video-background" autoPlay loop muted>
        <source src={home_video_url} type="video/mp4" />
      </video>

      <select className='select'
        value={activeLanguage}
        onChange={(e) => setActiveLanguage(e.target.value)} disabled={userInputsDisabled}>
        {localizations.map((localization) => (
          <option key={localization.localization_code} value={localization.localization_code}>
            {localization.localization_localized_name}
          </option>
        ))}
      </select>

      <div className='center'>
        <button className="start-app-button" onClick={handleStartAppClick} disabled={userInputsDisabled}>
          {startAppButtonText}
        </button>
      </div>
      
      {showSkipButton && (
        <button className="skip-button" onClick={handleSkipButtonClick}>
          {skipButtonText}
        </button>
      )}

      <img src={safran_logo_url} className="safranLogo" />

    </div>
  );
}

export default Home;