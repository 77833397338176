// src/components/Scoreboard.js
import React, { useContext, useEffect, useState } from 'react';
import { mediaConfig } from '../config/config.js';
import { StateContext } from '../context/stateContext';
import { ScoreContext } from '../context/scoreContext.js';
import { LocalizationContext } from '../context/localizationContext';
import { fetchUIElementsByStateAndLocalizationCode } from '../services/ui_elements.service';
import '../css/Quiz.css';

function Scoreboard() {
    const { quiz_video_url, safran_logo_url } = mediaConfig;
    const { score, resetScore } = useContext(ScoreContext);
    const { currentState, setCurrentState, stateNameToInteger } = useContext(StateContext);
    const { activeLanguage } = useContext(LocalizationContext);
    const [scoreboardTitle, setScoreboardTitle] = useState('');
    const [scoreboardSubtitle, setScoreboardSubtitle] = useState('');
    const [replayText, setReplayText] = useState('');
    const [returnHomeText, setReturnHomeText] = useState('');

    useEffect(() => {
        fetchUIElementsByStateAndLocalizationCode(stateNameToInteger[currentState], activeLanguage)
            .then((uiElements) => {
                const titleElement = uiElements.find((element) => element.element_name === 'scoreboard_title');
                const subtitleElement = uiElements.find((element) => element.element_name === 'run_score_title');
                const replayElement = uiElements.find((element) => element.element_name === 'replay_button');
                const returnElement = uiElements.find((element) => element.element_name === 'return_home_button');

                if (titleElement) {
                    const elementText = titleElement.element_text;

                    if (elementText) {
                        setScoreboardTitle(elementText);
                    } else {
                        console.log('scoreboard_title Element not found.');
                    }
                }

                if (subtitleElement) {
                    const elementText = subtitleElement.element_text;
                    if (elementText) {
                        setScoreboardSubtitle(elementText);
                    } else {
                        console.log('run_score_title Element not found.');
                    }
                }

                if(returnElement) {
                    const elementText = returnElement.element_text;
                    if (elementText) {
                        setReturnHomeText(elementText);
                    } else {
                        console.log('return_home_button Element not found.');
                    }
                }

                if(replayElement) {
                    const elementText = replayElement.element_text;
                    if (elementText) {
                        setReplayText(elementText);
                    } else {
                        console.log('replay_button Element not found.');
                    }
                }

            })
            .catch((error) => {
                console.error('Error fetching UI elements:', error);
            });
    }, [activeLanguage, currentState, stateNameToInteger]);

    const handleReplay = () => {
        resetValues();
        setCurrentState('quiz');
      };

      const handleReturnHome = () => {
        resetValues();
        setCurrentState('home');
      };

      const resetValues = () => {
        resetScore();
      }

    return (
        <div className="App" style={{ backgroundImage: `url(${quiz_video_url})` }}>
            <video className="video-background" autoPlay loop muted>
                <source src={quiz_video_url} type="video/mp4" />
            </video>
            <div className='header'>
                <h1 className="title">{scoreboardTitle}</h1>
                <h2 className="subtitle">{scoreboardSubtitle} {score}</h2>
            </div>
            <div className='center'>
            <button className='confirm-button replay-button' onClick={handleReplay}>
                {replayText}
              </button>
              <button className='confirm-button' onClick={handleReturnHome}>
                {returnHomeText}
              </button>
            </div>
            <img src={safran_logo_url} className="safranLogo" />
        </div>
    );
}

export default Scoreboard;
