//localization.service.js
import { LOCALIZATIONS_ENDPOINT } from '../config/apiConfig';

export const fetchLocalizations = async () => {
  try {
    const response = await fetch(LOCALIZATIONS_ENDPOINT);
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      throw new Error('Failed to fetch localizations');
    }
  } catch (error) {
    console.error('Error fetching localizations:', error);
    throw error;
  }
};
